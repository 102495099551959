<script type="text/babel">
import { isChanged } from "@/store/helpers";
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { REMOVE_SELECTED_LOCALE_BY_CODE } from "@/store/modules/locales/mutation-types";
import {
  GET_EMPTY_LOCALIZATION,
  GET_VISIBLE_LOCALE_CODES
} from "@/store/modules/locales/getter-types";
import { IS_LOADING } from "@/store/templates/crud/getter-types";

const { mapGetters } = createNamespacedHelpers("locales");

export default {
  name: "v-locale-text-mixin",
  inject: ["$validator"],
  props: {
    value: {
      type: Object,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    placeholders: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    counter: {
      type: Number,
      default: 255
    },
    label: {
      type: String,
      default: undefined
    },
    dataVvName: {
      type: String,
      default: undefined
    },
    errorMessages: {
      type: Array,
      required: true
    },
    alwaysVisibleLocales: {
      type: Array,
      default: null
    },
    requiredLocales: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    localeFields: {}
  }),
  computed: {
    ...mapGetters({
      visibleLocaleCodes: GET_VISIBLE_LOCALE_CODES,
      isLocalesLoading: IS_LOADING
    }),
    visibleLocaleFields() {
      const visibleLocales = this.alwaysVisibleLocales
        ? [
            ...new Set([
              ...this.visibleLocaleCodes,
              ...this.alwaysVisibleLocales
            ])
          ]
        : this.visibleLocaleCodes;
      return Object.keys(this.localeFields)
        .filter(key => key !== "id")
        .filter(key => visibleLocales.includes(key));
    },
    validationRules() {
      return `max:${this.counter}`;
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (isNil(newValue)) {
          this.localeFields = Object.assign(
            {},
            this.$store.getters[`locales/${GET_EMPTY_LOCALIZATION}`]
          );
        } else {
          this.localeFields = Object.assign({}, this.value);
        }
      },
      deep: true,
      immediate: true
    },
    localeFields: {
      handler(newValue) {
        // Check if the object is not nil and has not nil property values.
        const hasNonNullNonIdProperties =
          !isNil(newValue) &&
          Object.entries(newValue)
            .filter(([prop]) => prop !== "id")
            .filter(([value]) => value !== null).length > 0;

        if (hasNonNullNonIdProperties && isChanged(newValue, this.value)) {
          this.$emit("input", newValue);
        }
      },
      deep: true
    }
  },
  methods: {
    removeLocale(localeCode) {
      this.$store.commit(
        `locales/${REMOVE_SELECTED_LOCALE_BY_CODE}`,
        localeCode
      );
    }
  }
};
</script>
