<template>
  <offer-rejection-reason />
</template>

<script type="text/babel">
import OfferRejectionReason from "../../components/offer-rejection-reason/OfferRejectionReason";

export default {
  name: "offer-rejection-reason-detail",
  inject: ["$validator"],
  components: {
    OfferRejectionReason
  }
};
</script>
