<template>
  <v-card color="white">
    <crud-header
      :title="name"
      :tabs="tabs"
      type="Offer Rejection Reason"
      store="offerRejectionReason"
      :deletable="hasRolesOrPermissions('offer_reject_reason_delete')"
      soft-delete
      @delete-success="$router.push({ name: 'offer-rejection-reasons' })"
      @save-success="$router.push({ name: 'offer-rejection-reasons' })"
    />
  </v-card>
</template>

<script type="text/babel">
import offerRejectionReasonModule from "@/store/modules/offer-rejection-reason";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";

const { mapGetters } = createNamespacedHelpers("offerRejectionReason");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "offerRejectionReason",
  offerRejectionReasonModule
);

export default {
  name: "offer-rejection-reason-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "id", "isSelectable"]),
    tabs() {
      return [
        {
          name: "Details",
          to: {
            name: "offer-rejection-reasons.show",
            params: { offerRejectionReasonId: this.id }
          }
        },
        {
          name: "Offers",
          to: {
            name: "offer-rejection-reasons.offers",
            params: { offerRejectionReasonId: this.id }
          }
        }
      ];
    }
  },
  methods: {
    hasRolesOrPermissions(permission) {
      return this.$store.getters["auth/hasRolesOrPermissions"](permission);
    }
  },
  ...detailViewLifecycleMethods
};
</script>
