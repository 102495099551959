<template>
  <table-overview
    title="Offer Rejection Reasons"
    store="offerRejectionReasons"
    :headers="[
      { text: 'Name', value: 'name' },
      {
        text: 'Is Selectable',
        value: 'isSelectable',
        sortable: false,
        align: 'center',
        class: 'v-table-th--icon'
      },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'offer_rejection_reasons_view'
      }
    ]"
    force-initial-sort
  >
    <offer-rejection-reason-table-row
      slot="table-row"
      slot-scope="{ item }"
      :offer-rejection-reason-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "@/components/generic/overview/TableOverview";
import offerRejectionReasonsModule from "@/store/modules/offer-rejection-reasons";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import OfferRejectionReasonTableRow from "@/components/offer-rejection-reason/OfferRejectionReasonTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "offerRejectionReasons",
  offerRejectionReasonsModule
);

export default {
  name: "offer-rejection-reasons-view",
  components: {
    OfferRejectionReasonTableRow,
    TableOverview
  },
  data: () => ({}),
  ...overviewLifecycleMethods
};
</script>
