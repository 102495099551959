<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="name"
      :counter="255"
      required
      :error-messages="errors.collect('name')"
      data-vv-name="name"
    />
    <v-locale-text-field
      v-model="label"
      label="Label"
      required
      :error-messages="errors.collect('label')"
      data-vv-name="label"
    />
    <v-locale-text-field
      v-model="customerLabel"
      label="Customer label"
      :error-messages="errors.collect('customerLabel')"
      data-vv-name="customerLabel"
    />
    <v-locale-textarea
      v-model="info"
      label="Info"
      required
      :error-messages="errors.collect('info')"
      data-vv-name="info"
    />
    <v-checkbox v-model="isSelectable" label="Is Selectable" />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VLocaleTextField from "@/components/generic/input/VLocaleTextField";
import VLocaleTextarea from "@/components/generic/input/VLocaleTextarea";

const { mapComputed } = createNamespacedHelpers("offerRejectionReason");

export default {
  name: "offer-rejection-reason",
  components: { VLocaleTextarea, VLocaleTextField },
  inject: ["$validator"],
  computed: {
    ...mapComputed([
      "offerRejectionReason",
      "name",
      "isSelectable",
      "label",
      "customerLabel",
      "info"
    ])
  }
};
</script>
