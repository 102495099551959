var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Offer Rejection Reasons","store":"offerRejectionReasons","headers":[
    { text: 'Name', value: 'name' },
    {
      text: 'Is Selectable',
      value: 'isSelectable',
      sortable: false,
      align: 'center',
      class: 'v-table-th--icon'
    },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'offer_rejection_reasons_view'
    }
  ],"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('offer-rejection-reason-table-row',{attrs:{"offer-rejection-reason-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }