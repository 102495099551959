<template>
  <v-layout v-if="isLocalesLoading" wrap>
    <v-flex xs12>
      <v-text-field :label="label" class="py-0" loading disabled hide-details />
    </v-flex>
  </v-layout>
  <v-layout
    v-else
    wrap
    :style="{
      marginTop: '10px',
      marginBottom: '10px'
    }"
  >
    <v-flex
      v-for="prop in visibleLocaleFields"
      :key="prop"
      :class="{
        'pb-0': true,
        'grey lighten-4': prop !== 'nl_be',
        placeFirst: prop === 'nl_be'
      }"
      :style="{
        borderLeft: '2px solid black !important'
      }"
      xs12
    >
      <v-textarea
        v-if="large"
        v-model="localeFields[prop]"
        v-validate="
          requiredLocales.includes(prop) || (prop === 'nl_be' && required)
            ? `required|${validationRules}`
            : validationRules
        "
        class="py-0"
        :placeholder="placeholders[prop] || undefined"
        :disabled="disabled"
        :readonly="readonly"
        :append-outer-icon="
          prop === 'nl_be' ? undefined : 'far fa-fw fa-times-circle'
        "
        :data-vv-name="`${dataVvName}.${prop}`"
        :error-messages="errors.collect(`${dataVvName}.${prop}`)"
        @click:append-outer="removeLocale(prop)"
      >
        <template slot="label">
          <span>
            {{ label }}&nbsp;<strong>{{ prop }}</strong>
          </span>
        </template>
      </v-textarea>
      <v-text-field
        v-else
        v-model="localeFields[prop]"
        v-validate="
          requiredLocales.includes(prop) || (prop === 'nl_be' && required)
            ? `required|${validationRules}`
            : validationRules
        "
        class="py-0"
        :placeholder="placeholders[prop] || undefined"
        :disabled="disabled"
        :readonly="readonly"
        :append-outer-icon="
          prop === 'nl_be' ? undefined : 'far fa-fw fa-times-circle'
        "
        :data-vv-name="`${dataVvName}.${prop}`"
        :error-messages="errors.collect(`${dataVvName}.${prop}`)"
        @click:append-outer="removeLocale(prop)"
      >
        <template slot="label">
          <span>
            {{ label }}&nbsp;<strong>{{ prop }}</strong>
          </span>
        </template>
      </v-text-field>
    </v-flex>
  </v-layout>
</template>
<script type="text/babel">
import VLocaleTextMixin from "@/components/generic/input/VLocaleTextMixin";

export default {
  name: "v-locale-text-field",
  mixins: [VLocaleTextMixin]
};
</script>
