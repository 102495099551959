<template>
  <tr>
    <td>
      {{ offer.id }}
    </td>
    <td>
      {{ company.name }}
    </td>
    <td>
      {{ customer.name }}
    </td>
    <td>
      <v-chip v-for="(subject, index) in maxSubjects" :key="index" label small>
        {{ subject.name }}
      </v-chip>
      <v-tooltip v-if="subjects.length > maxChips" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(subject, index) in subjects" :key="index" label>
            {{ subject.name }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'submissions.show',
          params: { submissionId: lead.submission }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapOfferGetters } = createNamespacedHelpers(
  "offerRejectionReason/offers"
);

export default {
  name: "offer-rejection-reason-offer-table-row",
  props: {
    offerId: {
      type: Number,
      required: true
    }
  },
  data: function() {
    return {
      maxChips: 5
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapOfferGetters([FIND_BY_ID]),
    offer() {
      return this[FIND_BY_ID](this.offerId);
    },
    company() {
      return this.$store.getters["offerRejectionReason/offers/company"](
        this.offerId
      );
    },
    customer() {
      return this.$store.getters["offerRejectionReason/offers/customer"](
        this.offerId
      );
    },
    lead() {
      return this.$store.getters["offerRejectionReason/offers/lead"](
        this.offerId
      );
    },
    subjects() {
      return this.$store.getters["offerRejectionReason/offers/subjects"](
        this.offerId
      );
    },
    maxSubjects() {
      return this.subjects.slice(0, this.maxChips);
    }
  }
};
</script>
