var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Offers","store":"offerRejectionReason/offers","headers":[
    { text: 'Id', value: 'id' },
    { text: 'Company name', sortable: false },
    { text: 'Customer name', sortable: false },
    { text: 'Subjects', sortable: false },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'offer_rejection_reasons_view'
    }
  ],"searchable":"","force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('offer-rejection-reason-offer-table-row',{attrs:{"offer-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }