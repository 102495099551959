<template>
  <tr>
    <td>
      {{ offerRejectionReason.name }}
    </td>
    <td class="text-sm-right">
      <v-icon :color="offerRejectionReason.isSelectable ? 'red' : 'grey'">
        {{ offerRejectionReason.isSelectable ? "check" : "clear" }}
      </v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'offer-rejection-reasons.show',
          params: { offerRejectionReasonId: offerRejectionReason.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapOfferRejectionReasonGetters } = createNamespacedHelpers(
  "offerRejectionReasons"
);

export default {
  name: "offer-rejection-reason-table-row",
  props: {
    offerRejectionReasonId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapOfferRejectionReasonGetters([FIND_BY_ID]),
    offerRejectionReason() {
      return this[FIND_BY_ID](this.offerRejectionReasonId);
    }
  }
};
</script>
