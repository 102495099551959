var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLocalesLoading)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"py-0",attrs:{"label":_vm.label,"loading":"","disabled":"","hide-details":""}})],1)],1):_c('v-layout',{style:({
    marginTop: '10px',
    marginBottom: '10px'
  }),attrs:{"wrap":""}},_vm._l((_vm.visibleLocaleFields),function(prop){return _c('v-flex',{key:prop,class:{
      'pb-0': true,
      'grey lighten-4': prop !== 'nl_be',
      placeFirst: prop === 'nl_be'
    },style:({
      borderLeft: '2px solid black !important'
    }),attrs:{"xs12":""}},[(_vm.large)?_c('v-textarea',{directives:[{name:"validate",rawName:"v-validate",value:(
        _vm.requiredLocales.includes(prop) || (prop === 'nl_be' && _vm.required)
          ? ("required|" + _vm.validationRules)
          : _vm.validationRules
      ),expression:"\n        requiredLocales.includes(prop) || (prop === 'nl_be' && required)\n          ? `required|${validationRules}`\n          : validationRules\n      "}],staticClass:"py-0",attrs:{"placeholder":_vm.placeholders[prop] || undefined,"disabled":_vm.disabled,"readonly":_vm.readonly,"append-outer-icon":prop === 'nl_be' ? undefined : 'far fa-fw fa-times-circle',"data-vv-name":(_vm.dataVvName + "." + prop),"error-messages":_vm.errors.collect((_vm.dataVvName + "." + prop))},on:{"click:append-outer":function($event){return _vm.removeLocale(prop)}},model:{value:(_vm.localeFields[prop]),callback:function ($$v) {_vm.$set(_vm.localeFields, prop, $$v)},expression:"localeFields[prop]"}},[_c('template',{slot:"label"},[_c('span',[_vm._v(" "+_vm._s(_vm.label)+" "),_c('strong',[_vm._v(_vm._s(prop))])])])],2):_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(
        _vm.requiredLocales.includes(prop) || (prop === 'nl_be' && _vm.required)
          ? ("required|" + _vm.validationRules)
          : _vm.validationRules
      ),expression:"\n        requiredLocales.includes(prop) || (prop === 'nl_be' && required)\n          ? `required|${validationRules}`\n          : validationRules\n      "}],staticClass:"py-0",attrs:{"placeholder":_vm.placeholders[prop] || undefined,"disabled":_vm.disabled,"readonly":_vm.readonly,"append-outer-icon":prop === 'nl_be' ? undefined : 'far fa-fw fa-times-circle',"data-vv-name":(_vm.dataVvName + "." + prop),"error-messages":_vm.errors.collect((_vm.dataVvName + "." + prop))},on:{"click:append-outer":function($event){return _vm.removeLocale(prop)}},model:{value:(_vm.localeFields[prop]),callback:function ($$v) {_vm.$set(_vm.localeFields, prop, $$v)},expression:"localeFields[prop]"}},[_c('template',{slot:"label"},[_c('span',[_vm._v(" "+_vm._s(_vm.label)+" "),_c('strong',[_vm._v(_vm._s(prop))])])])],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }