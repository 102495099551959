<template>
  <table-overview
    title="Offers"
    store="offerRejectionReason/offers"
    :headers="[
      { text: 'Id', value: 'id' },
      { text: 'Company name', sortable: false },
      { text: 'Customer name', sortable: false },
      { text: 'Subjects', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'offer_rejection_reasons_view'
      }
    ]"
    searchable
    force-initial-sort
  >
    <offer-rejection-reason-offer-table-row
      slot="table-row"
      slot-scope="{ item }"
      :offer-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import OfferRejectionReasonOfferTableRow from "../../components/offer-rejection-reason/OfferRejectionReasonOfferTableRow";

export default {
  name: "offer-rejection-reason-offers-view",
  components: {
    OfferRejectionReasonOfferTableRow,
    TableOverview
  }
};
</script>
